import React, {useEffect, useState} from 'react';
import LogoTitle from '../../assets/images/logo-a.png'
import {Link} from "react-router-dom";
import './index.scss';
import AnimatedLetters from "../AnimatedLetters";
import Logo from "./Logo";
import Loader from "react-loaders";


const Home = () => {
    const [letterClass, setLetterClass] = useState('text-animate');
    const nameArray = ['l', 'e', 'x', 'e', 'i'];
    const jobArray = ['w', 'e', 'b', ' ', 'd', 'e', 'v', 'e', 'l', 'o', 'p', 'e', 'r'];

    const textDelay = () => {
        return setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 100)
    }

    useEffect(() => {
       textDelay()

    }, [])

    return (
        <div className='container home-page'>
            <div className='text-zone'>
                <h1>
                    <span className={letterClass}>H</span>
                    <span className={`${letterClass} _12`}>i,</span>
                     <br/>
                    <span className={`${letterClass} _13`}>I</span>
                    <span className={`${letterClass} _14`}>'m</span>
                    <img src={LogoTitle} alt="developer"/>
                    <AnimatedLetters letterClass={letterClass} strArray={nameArray} idx={15}/>
                    <br/>
                    <AnimatedLetters letterClass={letterClass} strArray={jobArray} idx={20}/>
                </h1>
                <h2>Frontend Developer</h2>
                <Link to='/contact' className='flat-button'>CONTACT ME</Link>
            </div>
            <Logo />
            <Loader type='pacman'  active />
        </div>

    );
};

export default Home;